<template>
  <a-modal
    class="modal_log"
    v-model:visible="visible"
    width="1000px"
    centered
    destroyOnClose
    :maskClosable="false"
    :footer="null"
    @cancel="current === 3 ? step4ok() : visible = false"
  >
    <template #title>
      <DecorateModalTitle modalTitle="接受邀请" />
    </template>
    <div class="step">
      <a-steps :current="current" size="small">
        <a-step title="输入邀请码" />
        <a-step title="阅读用户协议" />
        <a-step title="新增或编辑关联企业" />
        <a-step title="成功接受邀请" />
      </a-steps>
    </div>
    <div class="body">
      <div>
        <Step1
          v-if="current === 0"
          @toNext="toNext"
          @cancel="visible = false"
          :type="type"
          :params="params"
        />
        <Step2
          v-if="current === 1"
          @toNext="toNext"
          @cancel="visible = false"
          :type="type"
          :params="params"
        />
        <Step3
          v-if="current === 2"
          @toNext="toNext"
          @cancel="visible = false"
          :type="type"
          :params="params"
        />
        <step4
          v-if="current === 3"
          @toNext="toNext"
          :type="type"
          :params="params"
          @ok="step4ok"
        />
      </div>
    </div>
    <!-- <template #footer> xiayibu </template> -->
  </a-modal>
</template>
<script>
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import Step1 from "@/components/relation/invited/Step1.vue";
import Step2 from "@/components/relation/invited/Step2.vue";
import Step3 from "@/components/relation/invited/Step3.vue";
import Step4 from "@/components/relation/invited/Step4.vue";
import { help } from "@/api/relation/api.js";
export default {
  components: {
    DecorateModalTitle,
    Step1,
    Step2,
    Step3,
    Step4,
  },
  data() {
    return {
      type: "",
      current: 0,
      visible: false,
      params: {
        params: {},
      },
      //   formState: {},
      //   labelCol: { style: { width: "80px" } },
      //   rules: {
      //     address: [{ required: true, message: "请输入" }],
      //   },
    };
  },
  methods: {
    async show(type) {
      let res = await help(type); //此处获取第一步的说明
      this.params.params.step1Content = res.data.data.content;
      this.type = type;
      this.current = 0;
      this.visible = true;
    },
    toNext(params) {
      if (params && params.params) {
        this.params = params;
      }
      this.current++;
    },
    step4ok() {
      this.$emit("done");
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.step {
  padding: 0 50px;
}
.body {
  margin-top: 40px;
  // display: flex;
  //   align-items: center;
  // justify-content: center;
  min-height: 300px;
}
</style>
