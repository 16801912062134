<template>
  <div
    style="
      background: #fff;
      padding: 20px 30px 20px 30px;
      border-radius: 4px;
      height: 100%;
    "
  >
    <div>
      <!-- <div class="moduleTitle">
        {{
          $store.state.config.getRelationName(
            $store.state.config.getRelationType()
          ) + "列表"
        }}
      </div> -->
      <TableTab :custom="true">
        <a-button type="primary" style="margin: 0 0 0 12px" @click="add">
          新建
        </a-button>
        <a-button
          type="primary"
          style="margin: 0 0 0 12px"
          @click="invited"
          ghost
        >
          接受邀请
        </a-button>
        <template #left>
          <div class="flex" style="align-items: center">
            <span class="moduleTitle">
              {{
                $store.state.config.getRelationName(
                  $store.state.config.getRelationType()
                ) + "列表"
              }}
            </span>
            <a-input
              class="input"
              v-model:value="formState.search"
              placeholder="请输入关键字进行搜索..."
              @keyup.enter="refreshData(true)"
              allowClear
              style="width: 300px"
              size="small"
              @change="changeInput"
            />
            <a-button
              type="primary"
              @click="refreshData(true)"
              style="margin-left: 4px; padding-left: 10px; padding-right: 10px"
            >
              <SearchOutlined />
            </a-button>
          </div>
        </template>
      </TableTab>
      <a-table
        rowKey="id"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :scroll="{ x: '100%' }"
        :loading="loading"
        size="small"
        style="min-height: calc(100vh - 329px)"
      >
        <template #serialNumber="{ index }">
          <span class="serialNumber">
            {{
              ($refs.Pagination.pageCurrent - 1) * $refs.Pagination.pageSize +
              index +
              1
            }}
          </span>
        </template>
        <template #name="{ record }">
          <div>
            <div
              :title="record.name"
              class="ellipsis link"
              @click="details(record)"
            >
              {{ record.name }}
            </div>
            <div style="color: #ccc" :title="record.code" class="ellipsis">
              {{ record.code }}
            </div>
          </div>
        </template>
        <template #company="{ record }">
          <div>
            <div :title="record.caName" class="ellipsis">
              {{ record.caName }}
            </div>
            <div style="color: #ccc" :title="record.caCode" class="ellipsis">
              {{ record.caCode }}
            </div>
          </div>
        </template>
        <template #contact="{ record }">
          <div>
            <div :title="record.contact" class="ellipsis">
              {{ record.contact }}
            </div>
            <div class="flex">
              <div
                style="color: #ccc; width: auto"
                :title="record.contactPhoneShow"
                class="ellipsis"
              >
                {{ record.contactPhoneShow }}
              </div>
              <span
                class="link"
                @click="showphone(record)"
                v-if="record.hidePhone !== false && record.contactPhoneShow"
                >【显示】</span
              >
            </div>
          </div>
        </template>
        <!-- <template #phone="{ record }">
          <span>
            {{
              record[$store.state.config.getRelationType()] &&
              record[$store.state.config.getRelationType()].phone
            }}
          </span>
        </template>
        <template #otherContact="{ record }">
          <div v-if="record[$store.state.config.getRelationType()]">
            <div
              v-for="(item, index) in record[
                $store.state.config.getRelationType()
              ].otherContact"
              :key="index"
            >
              <div>
                {{ item.contact }}
                {{ item.phone }}
              </div>
            </div>
          </div>
        </template> -->
        <template #status1="{ record }">
          <a-switch
            :checked="record.status1 === true"
            @change="changeStatus(record)"
          />
        </template>
        <template #handle="{ record }">
          <a-button
            type="link"
            @click="handleBtn(record)"
            :disabled="record.binding === '已绑定'"
          >
            <!-- :disabled="record.status != 2  || record.status1 === false" -->
            <span v-if="record.binding === '邀请中'">已邀请</span>
            <span v-else-if="record.binding === '已绑定'">已绑定</span>
            <span v-else>邀请</span>
          </a-button>
          <!-- <a-button
            type="link"
            @click="del(record)"
            danger
            v-if="record.binding !== '已绑定'"
            :disabled="record.status1 === false"
          >
            <span>删除</span>
          </a-button> -->
        </template>
        <template #tags="{ record }">
          <span :title="record.tags ? record.tags.join('、') : ''">
            {{ record.tags ? record.tags.join("、") : "" }}
          </span>
        </template>
      </a-table>
    </div>
    <!--    //初始20传20 -->
    <Pagination
      :total="total"
      @changePagination="changePagination"
      :defaultPageSize="10"
      :pageSizeOptions="['10', '20', '50', '100']"
      ref="Pagination"
    >
    </Pagination>
    <AddModal ref="AddModal" @done="refreshData(true)" />
    <InvitedModal ref="InvitedModal" @done="refreshData(true)" />
    <Invite ref="Invite" @done="refreshData(true)" />
  </div>
</template>
<script >
import { defineComponent, createVNode } from "vue";
import TableTab from "@/components/list/TableTab.vue";
import Pagination from "@/components/list/Pagination.vue";
import AddModal from "@/components/relation/Add.vue";
import InvitedModal from "@/components/relation/Invited.vue";
import Invite from "@/components/relation/Invite.vue";
import { getList, edit, phone, delData } from "@/api/relation/api.js";
import Screen from "@/components/list/Screen.vue";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";

export default defineComponent({
  components: {
    TableTab,
    Pagination,
    AddModal,
    InvitedModal,
    Invite,
    Screen,
    SearchOutlined,
    ExclamationCircleOutlined,
  },
  data() {
    return {
      formState: {},
      data: [],
      columns: [
        {
          title: "序号",
          dataIndex: "serialNumber",
          slots: { customRender: "serialNumber" },
          width: 70,
          align: "center",
          fixed: "left",
        },
        {
          title: "名称/编码",
          dataIndex: "name",
          slots: { customRender: "name" },
          align: "left",
          width: 120,
          ellipsis: true,
        },
        {
          title: "企业名称/社会信用统一代码",
          dataIndex: "company",
          slots: { customRender: "company" },
          align: "left",
          width: 180,
          ellipsis: true,
        },
        {
          title: "地址",
          dataIndex: "address",
          slots: { customRender: "address" },
          align: "left",
          width: 150,
          ellipsis: true,
        },
        {
          title: "联系人",
          dataIndex: "contact",
          slots: { customRender: "contact" },
          align: "left",
          width: 100,
          ellipsis: true,
        },
        // {
        //   title: "联系电话",
        //   dataIndex: "phone",
        //   slots: { customRender: "phone" },
        //   align: "left",
        //   width: 120,
        //   ellipsis: true,
        // },
        // {
        //   title: "其他联系人",
        //   dataIndex: "otherContact",
        //   slots: { customRender: "otherContact" },
        //   align: "left",
        //   width: 120,
        //   ellipsis: true,
        // },
        {
          title: "标签",
          dataIndex: "tags",
          slots: { customRender: "tags" },
          align: "left",
          width: 100,
          ellipsis: true,
        },
        {
          title: "状态",
          dataIndex: "status1",
          slots: { customRender: "status1" },
          width: 120,
          align: "center",
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "handle",
          slots: { customRender: "handle" },
          align: "center",
          fixed: "right",
          width: 140,
        },
      ],
      //总数据量
      total: 0,
      //批量操作按钮加载
      loading: true,
    };
  },
  methods: {
    refreshData(toFirst = false) {
      const sessionNum =
        Number(
          sessionStorage.getItem(this.$route.path.replace(/\//g, "") + "Num")
        ) || 1;
      //初始20传20
      const sesstionSize =
        Number(
          sessionStorage.getItem(this.$route.path.replace(/\//g, "") + "Size")
        ) || 10;
      if (toFirst) {
        sessionStorage.setItem(this.$route.path.replace(/\//g, "") + "Num", 1);
        this.$refs.Pagination.pageCurrent = 1;
      }
      this.getData(sesstionSize, toFirst ? 1 : sessionNum);
    },
    changePagination(page) {
      this.getData(page.pageSize, page.pageNum);
    },
    //初始20传20
    getData(pageSize = 10, pageNum = 1) {
      this.loading = true;
      getList(
        { size: pageSize, page: pageNum - 1, ...this.formState },
        {
          // kind: this.$store.state.config.getRelationType(),
        },
        this.$store.state.config.getRelationType()
      )
        .then((res) => {
          let result = res.data;
          this.total = result.total;
          this.data = result.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //新建
    add() {
      const type = this.$store.state.config.getRelationType(); //获取页面类型
      this.$refs.AddModal.show(type);
    },
    //接受邀请
    invited() {
      const type = this.$store.state.config.getRelationType(); //获取页面类型
      this.$refs.InvitedModal.show(type);
    },
    async changeStatus(record) {
      let data = JSON.parse(JSON.stringify(record));
      data.status1 = !data.status1;
      await edit(
        {
          id: data.id,
          status: data.status1,
        },
        this.$store.state.config.getRelationType()
      );
      this.refreshData();
    },
    handleBtn(record) {
      const type = this.$store.state.config.getRelationType(); //获取页面类型
      //邀请打开0  已邀请打开1
      if (record.binding === "邀请中") {
        this.$refs.Invite.show(type, record, 1);
      } else {
        this.$refs.Invite.show(type, record, 0);
      }
    },
    //改变搜索框的值
    changeInput(val) {
      if (val.type === "click") {
        this.refreshData(true);
      }
    },
    details(record) {
      const type = this.$store.state.config.getRelationType(); //获取页面类型
      this.$refs.AddModal.show(type, record);
    },
    async showphone(record) {
      this.hidePhone = false;
      const res = await phone(
        {
          id: record.id,
          phone: record.contactPhone,
        },
        this.$store.state.config.getRelationType()
      );
      record.contactPhoneShow = res.data.data;
      record.hidePhone = false;
    },
    del(record) {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: `确定要删除此条${this.$store.state.config.getRelationName(
          this.$store.state.config.getRelationType()
        )}数据吗`,
        centered: true,
        okText: "删除",
        cancelText: "取消",
        async onOk() {
          let res = await delData(
            record.id,
            that.$store.state.config.getRelationType()
          );
          if (res.data.success) {
            that.$message.success("删除成功");
            that.refreshData(true);
          }
        },
      });
    },
  },
  mounted() {
    this.refreshData(true);
  },
});
</script>
<style  lang="less" scoped>
.moduleTitle {
  height: 20px;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  font-weight: 600;
  margin-right: 20px;
}
.link {
  cursor: pointer;
  color: #5a80b0;
}
</style>
