<template>
  <div>
    <div style="height: 240px; display: flex; justify-content: center">
      <a-result
        style="margin-top: -20px"
        status="success"
        title="成功接受邀请"
        :sub-title="params.params.step3Content"
      >
        <template #extra>
          <a-button type="primary" @click="sub">完成</a-button>
        </template>
      </a-result>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {}, //入口类型
    params: {}, //前面步骤传参
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    sub() {
      this.$emit("ok");
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
}
:deep(.ant-result-title){
  font-size: 20px;
}
</style>
