<template>
  <a-modal
    class="modal_log"
    v-model:visible="visible"
    width="600px"
    centered
    @ok="ok"
    :confirmLoading="confirmLoading"
    destroyOnClose
    :maskClosable="false"
  >
    <template #title>
      <DecorateModalTitle :modalTitle="modalTitle" />
    </template>
    <div class="body">
      <a-form
        :model="formState"
        :rules="rules"
        ref="formRef"
        :labelCol="labelCol"
      >
        <!-- <div class="sub_title">基本信息</div> -->
        <!-- 编码不可编辑, 当前系统临时禁止修改已存在的编码 -->
        <a-form-item label="编码" name="code">
          <a-input
            v-model:value.trim="formState.code"
            :maxlength="100"
            :disabled="formState.id !== undefined"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="名称" name="name">
          <a-input
            v-model:value.trim="formState.name"
            :maxlength="100"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="地址" name="address">
          <a-input
            v-model:value.trim="formState.address"
            :maxlength="100"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="联系人" name="contact">
          <a-input
            v-model:value.trim="formState.contact"
            :maxlength="100"
            placeholder="请输入"
          />
        </a-form-item>
        <div class="flex">
          <a-form-item
            label="联系电话"
            name="contactPhoneShow"
            style="min-width: 100%"
          >
            <a-input
              v-model:value.trim="formState.contactPhoneShow"
              :maxlength="11"
              @change="changePhone = true"
              placeholder="请输入"
            />
          </a-form-item>
          <a-button
            v-if="hidePhone && modalTitle !== '新增'"
            type="link"
            @click="showphone"
            style="padding: 0"
          >
            【显示】
          </a-button>
        </div>
        <a-form-item label="标签" name="contact">
          <a-select
            v-model:value="formState.tags"
            mode="tags"
            style="width: 100%"
            :token-separators="[',','，']"
            placeholder="请选择或输入 可用,分割标签"
            :options="tagsOptions"
            :maxTagTextLength="8"
          ></a-select>
        </a-form-item>

        <!-- <div class="sub_title flex between">
          <div v-if="formState.otherContact.length">其他联系人</div>
          <a-button
            type="link"
            size="small"
            @click="addotherContact"
            v-if="formState.contact && formState.phone"
          >
            + 增加其他联系人
          </a-button>
        </div>
        <div
          class="flex other_box"
          v-for="(item, index) in formState.otherContact"
          :key="keyList[index]"
        >
          <a-form-item label="名称" name="contact">
            <a-input v-model:value.trim="item.contact" :maxlength="100" />
          </a-form-item>
          <a-form-item label="电话" name="phone">
            <a-input v-model:value.trim="item.phone" :maxlength="100" />
          </a-form-item>
          <a-button type="text" danger class="del" @click="del(index)"
            ><DeleteOutlined
          /></a-button>
        </div> -->
      </a-form>
    </div>
  </a-modal>
</template>
<script>
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import {
  create,
  getDetails,
  update,
  phone,
  getOptions,
} from "@/api/relation/api";
export default {
  components: {
    DecorateModalTitle,
    DeleteOutlined,
  },
  data() {
    return {
      type: "", //来自哪个页面
      tagsOptions: [],
      formState: {
        // otherContact: [
        // {
        //   contact: null,
        //   phone: null,
        // },
        // ],
      },
      visible: false,
      labelCol: { style: { width: "80px" } },
      confirmLoading: false,
      rules: {
        // address: [{ required: true, message: "请输入" }],
        name: [{ required: true, message: "请输入" }],
        code: [
          {
            required: true,
            validator: async (rule, value) => {
              var reg = new RegExp("^[A-Za-z0-9\\-]+$");
              if (value === undefined || value === null) {
                value = "";
              }
              if (value === "") {
                return Promise.reject("请输入");
              }
              if (!reg.test(value)) {
                return Promise.reject("仅支持字母、数字和-");
              }
            },
          },
        ],
        contactPhoneShow: [
          {
            validator: async (rule, value) => {
              if (value === undefined || value === null) {
                value = "";
              }
              // 如果未改过号码允许中间有* 改过不允许
              var reg = this.changePhone
                ? /^\d{11}$/
                : /^\d{11}$|^\d{3}\*{4}\d{4}$/;
              var value = value.trim();
              if (!reg.test(value) && value.length != 0) {
                return Promise.reject("请输入正确的手机号码");
              }
            },
          },
        ],
      },
      keyList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      modalTitle: "新增",
      hidePhone: true,
      changePhone: false,
    };
  },
  methods: {
    async show(type, record) {
      //如果有record 就是编辑页面
      this.hidePhone = true;
      if (record) {
        const res = await getDetails(record.id, type);
        this.modalTitle = "编辑";
        if (!res.data.data.tags) {
          res.data.data.tags = [];
        }
        this.formState = res.data.data;
        if (!this.formState.contactPhoneShow) {
          this.hidePhone = false;
        }
      } else {
        this.modalTitle = "新增";
        this.formState = {
          // otherContact: [
          // {
          //   contact: null,
          //   phone: null,
          // },
          // ],
        };
      }
      this.changePhone = false;
      this.type = type;
      this.visible = true;
      //   if (this.$refs.formRef) {
      //     this.$refs.formRef.resetFields();
      //   }
    },
    async ok() {
      await this.$refs.formRef.validate();
      this.confirmLoading = true;
      let res = {};
      if (!this.formState.id) {
        res = await create(
          {
            code: this.formState.code,
            name: this.formState.name,
            address: this.formState.address,
            contact: this.formState.contact,
            contactPhoneShow: this.formState.contactPhoneShow,
            tags: this.formState.tags,
          },
          this.type
        );
      } else {
        let contactPhoneShow = this.formState.contactPhoneShow;
        res = await update(
          this.formState.id,
          {
            code: this.formState.code,
            name: this.formState.name,
            address: this.formState.address,
            contact: this.formState.contact,
            contactPhoneShow: contactPhoneShow,
            tags: this.formState.tags,
          },
          this.type
        );
      }
      if (res.data.success) {
        this.$emit("done");
        this.visible = false;
        this.$message.success("操作成功");
      }
      this.confirmLoading = false;
    },
    addotherContact() {
      if (this.formState.otherContact.length >= 4) {
        this.$message.warning("最多只能增加4个其他联系人");
        return;
      } else {
        this.formState.otherContact.push({
          contact: null,
          phone: null,
        });
      }
    },
    del(index) {
      this.formState.otherContact.splice(index, 1);
    },
    async showphone() {
      this.hidePhone = false;
      const res = await phone(
        {
          id: this.formState.id,
          phone: this.formState.contactPhone,
        },
        this.type
      );
      this.formState.contactPhoneShow = res.data.data;
    },
    async getTagOptions() {
      let res = await getOptions();
      if (res.data.success) {
        this.tagsOptions = res.data.data.map((el) => {
          return {
            value: el,
            label: el,
          };
        });
      }
    },
  },
  mounted() {
    this.getTagOptions();
  },
};
</script>
<style lang="less" scoped>
.body {
  padding: 0 80px 0 60px;
}
.sub_title {
  margin-bottom: 10px;
  font-weight: 600;
}
:deep(.ant-form-item) {
  margin-bottom: 14px;
}
.other_box {
  position: relative;
  .del {
    position: absolute;
    right: -47px;
  }
}
</style>
