<template>
  <a-modal
    class="modal_log"
    v-model:visible="visible"
    width="700px"
    centered
    destroyOnClose
    :maskClosable="false"
    :footer="null"
    @cancel="current === 1 ? ok() : visible = false"
  >
    <template #title>
      <DecorateModalTitle modalTitle="邀请" />
    </template>
    <div class="step">
      <a-steps :current="current" size="small">
        <a-step title="阅读用户协议" />
        <a-step title="成功发起邀请" />
      </a-steps>
    </div>
    <div class="body">
      <div>
        <Step1
          v-if="current === 0"
          @toNext="toNext"
          @cancel="visible = false"
          :type="type"
          :params="params"
        />
        <Step2
          v-if="current === 1"
          @toNext="toNext"
          :type="type"
          :params="params"
          @ok="ok"
        />
        <!-- 当前页面取消也是确认 -->
      </div>
    </div>
    <!-- <template #footer> xiayibu </template> -->
  </a-modal>
</template>
<script>
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import Step1 from "@/components/relation/Invite/Step1.vue";
import Step2 from "@/components/relation/Invite/Step2.vue";
import { senderProtocol, senderCodeInfo } from "@/api/relation/api.js";

export default {
  components: {
    DecorateModalTitle,
    Step1,
    Step2,
  },
  data() {
    return {
      type: "",
      current: 0,
      visible: false,
      params: {},
      //   formState: {},
      //   labelCol: { style: { width: "80px" } },
      //   rules: {
      //     address: [{ required: true, message: "请输入" }],
      //   },
    };
  },
  methods: {
    async show(type, record, current) {
      this.params = record;
      this.type = type;
      this.current = current;
      if (current === 0) {
        let res = await senderProtocol(this.params.id, this.type);
        if (res.data.success) {
          this.params.protocols = res.data.data;
          this.visible = true;
        }
      } else {
        let res = await senderCodeInfo(this.params.id, this.type);
        if (res.data.success) {
          this.params.protocols = res.data.data;
          this.visible = true;
        }
      }
    },
    toNext(params) {
      if (params && params.params) {
        this.params = params;
      }
      this.current++;
    },
    ok() {
      this.$emit("done");
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.step {
  padding: 0 50px;
}
.body {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  margin-top: 40px;
  min-height: 200px;
}
</style>
