<template>
  <div>
    <div
      style="
        height: 240px;
        display: flex;
        justify-content: center;
        padding-top: 50px;
      "
    >
      <div style="padding: 0 25%">
        <div style="margin-bottom: 20px">
          <!-- 确定接受邀请，成为 {{ params.params.caName }} 用户的【{{
            $store.state.config.getRelationName(params.params.inviteTenantKind)
          }}】？ -->
          {{ params.params.content }}
        </div>
        <div>
          <a-checkbox v-model:checked="checked">我已阅读并同意</a-checkbox
          ><span
            class="agreement"
            v-for="(item, index) in params.params.protocols"
            :key="item.code"
          >
            <span v-if="index !== 0">、</span>
            <span @click="agreement(item)"> {{ item.name }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button style="margin: 0 0 0 12px" @click="cancel"> 取消 </a-button>
      <a-button
        type="primary"
        style="margin: 0 0 0 12px"
        @click="toNext"
        :disabled="!checked"
      >
        下一步
      </a-button>
    </div>
  </div>
</template>
<script>
import { protocolConfirm } from "@/api/relation/api.js";
export default {
  props: {
    type: {}, //入口类型
    params: {},
  },
  components: {},
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async toNext() {
      if (this.checked) {
        console.log(this.params);
        let res = await protocolConfirm(
          { code: this.params.params.step1Code },
          this.type
        );
        if (res.data.data.editCode) {
          let params = this.params.params;
          params.step2Code = res.data.data.editCode || "没有拿到返回数据"; //这是编辑码
          params.step2caName = res.data.data.caName;
          params.step2caCode = res.data.data.caCode;
          this.$emit("toNext", { from: 2, to: 3, params: params });
        }
      } else {
        this.$message.warning("请先阅读用户协议");
      }
    },
    agreement(el) {
      window.open(el.uri);
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
}
.agreement {
  cursor: pointer;
  color: #008bcf;
}
// :deep(.ant-checkbox + span){
//   padding-right: 0 !important;
// }
</style>
