<template>
  <div>
    <div
      style="
        min-height: 240px;
        padding-top: 10px;
        display: flex;
        justify-content: center;
      "
    >
      <a-form
        :model="formState"
        :rules="rules"
        ref="formRef"
        :labelCol="{ style: { width: '60px' } }"
      >
        <div class="flex">
          <!-- {{ formState }} -->
          <!-- {{ params }} -->
          <div style="margin-right: 80px; width: 300px">
            <a-form-item
              label="客户类别"
              :labelCol="{ style: { width: '120px' } }"
            >
              {{ $store.state.config.getRelationName(type) }}
            </a-form-item>
            <a-form-item
              label="企业名称"
              :labelCol="{ style: { width: '120px' } }"
            >
              {{ params.params.step2caName }}
            </a-form-item>
            <a-form-item
              label="统一社会信用代码"
              :labelCol="{ style: { width: '120px' } }"
            >
              {{ params.params.step2caCode }}
            </a-form-item>
          </div>
          <div>
            <!-- <div class="sub_title">基本信息</div> -->
            <a-form-item label="编码" name="code" class="icon_box">
              <a-input
                v-model:value.trim="formState.code"
                :maxlength="100"
                style="width: 200px"
                @change="changeCode"
              />
              <LoadingOutlined v-show="iconShow" class="icon" />
              <span class="icon_text" v-if="!bindable">
                {{ bindMessage || "不可进行绑定" }}
              </span>
              <span class="icon_text exist" v-if="exist && bindable">
                {{ bindMessage || "已存在可绑定" }}
              </span>
            </a-form-item>
            <a-form-item label="名称" name="name">
              <a-input
                v-model:value.trim="formState.name"
                :maxlength="100"
                style="width: 200px"
              />
            </a-form-item>
            <a-form-item label="地址" name="address">
              <a-input
                v-model:value.trim="formState.address"
                :maxlength="100"
                style="width: 200px"
              />
            </a-form-item>
            <a-form-item label="联系人" name="contact">
              <a-input
                v-model:value.trim="formState.contact"
                :maxlength="100"
                style="width: 200px"
              />
            </a-form-item>
            <a-form-item
              label="联系电话"
              name="contactPhoneShow"
              class="phone_box"
            >
              <a-input
                v-model:value.trim="formState.contactPhoneShow"
                :maxlength="11"
                style="width: 200px"
                @change="
                  () => {
                    changePhone = true;
                    hidePhone = false;
                  }
                "
              />
              <a-button
                v-if="
                  hidePhone &&
                  formState.contactPhoneShow &&
                  formState.contactPhoneShow.includes('*')
                "
                type="link"
                @click="showphone"
                style="padding: 0"
                class="btn"
              >
                【显示】
              </a-button>
            </a-form-item>
            <!-- <div style="width: 400px">
              <div class="sub_title flex between">
                <div
                  v-if="formState.otherContact && formState.otherContact.length"
                >
                  其他联系人
                </div>
                <a-button type="link" size="small" @click="addotherContact">
                  + 增加其他联系人
                </a-button>
              </div>
              <div
                class="flex other_box"
                v-for="(item, index) in formState.otherContact"
                :key="keyList[index]"
              >
                <a-form-item label="名称" name="contact">
                  <a-input v-model:value.trim="item.contact" :maxlength="100" />
                </a-form-item>
                <a-form-item label="电话" name="phone">
                  <a-input v-model:value.trim="item.phone" :maxlength="100" />
                </a-form-item>
                <a-button type="text" danger class="del" @click="del(index)"
                  ><DeleteOutlined
                /></a-button>
              </div>
            </div> -->
          </div>
        </div>
      </a-form>
    </div>
    <div class="footer">
      <a-button style="margin: 0 0 0 12px" @click="cancel"> 取消 </a-button>
      <a-button
        type="primary"
        style="margin: 0 0 0 12px"
        @click="toNext"
        :loading="loading"
        :disabled="!bindable"
      >
        下一步
      </a-button>
    </div>
  </div>
</template>
<script>
import { invite, acceptData, phone } from "@/api/relation/api.js";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons-vue";
export default {
  props: {
    type: {}, //入口类型
    params: {}, //前面步骤传参
  },
  components: { DeleteOutlined, LoadingOutlined },
  data() {
    return {
      formState: {
        // otherContact: [
        // {
        //   contact: null,
        //   phone: null,
        // },
        // ],
      },
      rules: {
        name: [{ required: true, message: "请输入" }],
        code: [
          {
            required: true,
            validator: async (rule, value) => {
              var reg = new RegExp("^[A-Za-z0-9\\-]+$");
              if (value === undefined || value === null) {
                value = "";
              }
              if (value === "") {
                return Promise.reject("请输入");
              }
              if (!reg.test(value)) {
                return Promise.reject("仅支持字母、数字和-");
              }
            },
          },
        ],
        contactPhoneShow: [
          {
            validator: async (rule, value) => {
              if (value === undefined || value === null) {
                value = "";
              }
              // 如果未改过号码允许中间有* 改过不允许
              var reg = this.changePhone
                ? /^\d{11}$/
                : /^\d{11}$|^\d{3}\*{4}\d{4}$/;
              var value = value.trim();
              if (!reg.test(value) && value.length != 0) {
                return Promise.reject("请输入正确的手机号码");
              }
            },
          },
        ],
      },
      loading: false,
      keyList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      timer: null,
      iconShow: false,
      bindable: true,
      exist: false,
      bindMessage: undefined,
      hidePhone: true,
      changePhone: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async toNext() {
      //注意传对应类型的数据即可
      await this.$refs.formRef.validate();
      this.loading = true;
      const res = await invite(
        this.formState,
        this.params.params.step2Code,
        this.type
      );
      this.loading = false;
      if (res.data.success) {
        let params = this.params.params;
        params.step3Content = res.data.data.content;
        this.$emit("toNext", { from: 3, to: 4, params: params });
      }
    },
    addotherContact() {
      let otherContact = this.formState.otherContact;
      if (otherContact === null || otherContact === undefined) {
        this.formState.otherContact = [];
      }
      if (this.formState.otherContact.length >= 4) {
        this.$message.warning("最多只能增加4个其他联系人");
        return;
      } else {
        this.formState.otherContact.push({
          contact: null,
          phone: null,
        });
      }
    },
    del(index) {
      this.formState.otherContact.splice(index, 1);
    },
    delayTimer(i, cb) {
      let j = 5; //5*100 ms之后触发
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        ++i;
        if (i == j) {
          cb();
          clearInterval(this.timer);
        }
      }, 100);
    },
    async changeCode() {
      this.delayTimer(0, async () => {
        if (this.formState.code) {
          this.iconShow = true;
          const res = await acceptData(this.formState.code, this.type);
          this.iconShow = false;
          this.bindable = res.data.data.bindable;
          this.exist = res.data.data.exist;
          this.bindMessage = res.data.data.message;
          if (res.data.data && res.data.data.exist) {
            this.formState = res.data.data;
            this.hidePhone = true;
            this.changePhone = false;
          } else {
            this.formState = {
              code: this.formState.code,
              name: this.formState.name,
              address: this.formState.address,
              contact:
                this.formState.contactPhoneShow &&
                this.formState.contactPhoneShow.includes("*")
                  ? ""
                  : this.formState.contact,
              contactPhoneShow:
                this.formState.contactPhoneShow &&
                this.formState.contactPhoneShow.includes("*")
                  ? ""
                  : this.formState.contactPhoneShow,
            };
          }
          this.$refs.formRef.resetFields();
        }
      });
    },
    async showphone() {
      this.hidePhone = false;
      const res = await phone(
        {
          id: this.formState.id,
          phone: this.formState.contactPhone,
        },
        this.type
      );
      this.formState.contactPhoneShow = res.data.data;
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.sub_title {
  margin-bottom: 10px;
  font-weight: 600;
}
.icon_box {
  position: relative;
  .icon,
  .icon_text {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .icon_text {
    left: 205px;
    color: #ff4d4f;
    width: 165px;
  }
}
.phone_box {
  position: relative;
  .btn {
    position: absolute;
    left: 205px;
  }
}
</style>
