<template>
  <div>
    <div style="min-height: 140px; padding: 0 18%">
      <!-- {{ params }} -->
      <div>邀请码：</div>
      <div style="white-space: pre-wrap; margin-bottom: 10px">
        {{ data }}
        <span class="link" @click="$store.state.same.copy(this.data)"
          >【复制】</span
        >
      </div>
      <div style="white-space: pre-wrap; margin-bottom: 10px">
        <span>有效期至：</span>
        {{ time }}
      </div>
      <div style="white-space: pre-wrap">
        {{ inviteOperation }}
      </div>
    </div>
    <div class="footer">
      <a-button
        type="default"
        danger
        style="margin: 0 0 0 12px"
        @click="invalid()"
      >
        作废邀请码
      </a-button>
      <a-button type="primary" style="margin: 0 0 0 12px" @click="copy">
        复制邀请码及方法
      </a-button>
      <a-button style="margin: 0 0 0 12px" @click="cancel"> 关闭 </a-button>
    </div>
  </div>
</template>
<script>
import { createVNode } from "vue";
import { idInvite, invalid } from "@/api/relation/api";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import moment from "moment";
export default {
  props: {
    type: {}, //入口类型
    params: {}, //params
  },
  components: { ExclamationCircleOutlined },
  data() {
    return {
      data: "",
      inviteOperation: "",
      time: "",
    };
  },
  methods: {
    cancel() {
      this.$emit("ok");
    },
    copy() {
      this.$store.state.same.copy(this.data + "\n" + this.inviteOperation, "");
    },
    async invalid() {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: `确定要作废此邀请码？`,
        centered: true,
        okText: "作废",
        cancelText: "取消",
        async onOk() {
          let res = await invalid(that.params.id, that.type);
          if (res.data.success) {
            that.$message.success("作废成功");
            that.$emit("ok");
          }
        },
      });
    },
  },
  async mounted() {
    // let res = { data: {} };
    // res.data.data = {
    //   code: "AffInvAbN0I6000000tR6EBEsLzQdI3UUaZKnl8tyz7o",
    //   content:
    //     "邀请方法：\r\n邀请码只展示一次，注意保存！\r\n弱邀请码遗忘或失效，请重新邀请。\r\n有效期120分钟，为确保绑定安全，请勿将邀请码发给不相关人员。",
    // };
    if (this.params.protocols && this.params.protocols.templateId) {
      let res = await idInvite(
        {
          id: this.params.id,
          templateId: this.params.protocols.templateId,
          templateVersion: this.params.protocols.templateVersion,
        },
        this.type
      );
      this.data = res.data.data.code;
      this.inviteOperation = res.data.data.content;
      this.time = moment(res.data.data.expiredAt).format("YYYY-MM-DD HH:mm:ss");
    } else {
      this.data = this.params.protocols.code;
      this.inviteOperation = this.params.protocols.content;
      this.time = moment(this.params.protocols.expiredAt).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
  },
};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}
.link {
  cursor: pointer;
  color: #5a80b0;
}
</style>
