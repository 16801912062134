<template>
  <div>
    <div
      style="
        min-height: 240px;
        padding: 50px 23% 0;
        display: flex;
        justify-content: center;
      "
    >
      <a-form :model="formState" :rules="rules" ref="formRef">
        <a-form-item label="请输入邀请码" name="code">
          <a-input
            v-model:value="formState.code"
            :maxlength="100"
            style="width: 400px"
          />
        </a-form-item>
        <div
          v-if="params.params && params.params.step1Content"
          style="white-space: pre-wrap"
        >
          <!-- padding: 0 0 0 95px -->
          {{ params.params.step1Content }}
        </div>
      </a-form>
    </div>
    <div class="footer">
      <a-button style="margin: 0 0 0 12px" @click="cancel"> 取消 </a-button>
      <a-button
        type="primary"
        style="margin: 0 0 0 12px"
        @click="toNext"
        :loading="loading"
      >
        下一步
      </a-button>
    </div>
  </div>
</template>
<script>
import { acceptInvite } from "@/api/relation/api.js";
export default {
  props: {
    type: {}, //入口类型
    params: {},
  },
  components: {},
  data() {
    return {
      formState: {},
      rules: {
        code: [{ required: true, message: "请输入" }],
      },
      loading: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async toNext() {
      // this.$emit("toNext", { from: 1, to: 2, params: "asdasdasd" }); //临时假数据
      //注意传对应类型的数据即可
      await this.$refs.formRef.validate();
      this.loading = true;
      const res = await acceptInvite(
        {
          code: this.formState.code,
        },
        this.type
      );
      this.loading = false;
      if (res.data.success) {
        res.data.data.step1Code = this.formState.code;
        this.$emit("toNext", { from: 1, to: 2, params: res.data.data });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}
</style>
