<template>
  <div>
    <div style="height: 140px; display: flex; justify-content: center">
      <div style="padding: 0 18%">
        <div style="margin-bottom: 20px">
          <!-- 确定邀请，用户 {{ params.name }} 成为您的【{{
            $store.state.config.getRelationName(type)
          }}】？ -->
          {{ params.protocols.content }}
        </div>
        <div>
          <a-checkbox v-model:checked="checked">我已阅读并同意</a-checkbox
          ><span
            class="agreement"
            v-for="(item, index) in params.protocols.protocols"
            :key="item.code"
          >
            <span v-if="index !== 0">、</span>
            <span @click="agreement(item)"> {{ item.name }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button style="margin: 0 0 0 12px" @click="cancel"> 取消 </a-button>
      <a-button
        type="primary"
        style="margin: 0 0 0 12px"
        @click="toNext"
        :disabled="!checked"
      >
        下一步
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {}, //入口类型
    params: {}, //params
  },
  components: {},
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    toNext() {
      if (this.checked) {
        this.$emit("toNext", { from: 2, to: 3 });
      } else {
        this.$message.warning("请先阅读用户协议");
      }
    },
    agreement(el) {
      window.open(el.uri);
      //  this.params.protocols
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
}
.agreement {
  cursor: pointer;
  color: #008bcf;
}
// :deep(.ant-checkbox + span){
//   padding-right: 0 !important;
// }
</style>
