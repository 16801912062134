<template>
  <div style="height: 10px" v-if="!custom"></div>
  <div
    style="
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      box-shadow: inset 0px -1px 0px 0px rgba(232, 232, 232, 1);
    "
  >
    <slot name="left">
      <div class="moduleTitle">{{ tabName }}</div>
    </slot>
    <div style="display: flex" v-if="custom">
      <slot></slot>
    </div>
    <div style="display: flex" v-else>
      <slot></slot>
      <a-button
        type="primary"
        style="margin: 0 0 0 12px"
        @click="add"
        class="spaceAroundBtn"
        v-if="addBtn"
      >
        <div class="spaceAroundBtn"><FileAddFilled /> 新建</div>
      </a-button>
      <a-popover>
        <template #content>
          <span>刷新</span>
        </template>
        <div class="SettingOutlinedBox" @click="search">
          <ReloadOutlined />
        </div>
      </a-popover>
      <a-popover trigger="click" placement="bottom">
        <template #content>
          <div class="batch">
            <div
              :class="$store.state.batchStyle == 'default' ? 'active' : ''"
              @click="$store.state.batchStyle = 'default'"
            >
              宽松
            </div>
            <div
              :class="$store.state.batchStyle == 'middle' ? 'active' : ''"
              @click="$store.state.batchStyle = 'middle'"
            >
              默认
            </div>
            <div
              :class="$store.state.batchStyle == 'small' ? 'active' : ''"
              @click="$store.state.batchStyle = 'small'"
            >
              紧凑
            </div>
          </div>
        </template>
        <a-popover>
          <template #content>
            <span>密度</span>
          </template>
          <div class="SettingOutlinedBox"><ColumnHeightOutlined /></div>
        </a-popover>
      </a-popover>
      <a-popover title="列展示" trigger="click" placement="bottomRight">
        <template #content>
          <div class="a-popoverContent">
            <div v-for="(i, index) in columns" :key="index">
              <div v-if="i.title !== '#'">
                <a-checkbox checked disabled />
                <span style="margin-left: 5px">{{ i.title }}</span>
              </div>
            </div>
          </div>
        </template>
        <a-popover placement="top">
          <template #content>
            <span>列设置</span>
          </template>
          <div class="SettingOutlinedBox" title="列设置">
            <SettingOutlined />
          </div>
        </a-popover>
      </a-popover>
    </div>
  </div>
</template>
<script>
import {
  FileAddFilled,
  ReloadOutlined,
  ColumnHeightOutlined,
  SettingOutlined,
} from "@ant-design/icons-vue";
export default {
  props: {
    tabName: {
      type: String,
      default: "",
    },
    addBtn: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: [],
    },
  },
  emits: ["search", "add"],
  components: {
    FileAddFilled,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
  },
  methods: {
    search() {
      this.$emit("search");
    },
    add() {
      this.$emit("add");
    },
  },
};
</script>
<style lang="scss" scoped>
.moduleTitle {
  height: 20px;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  font-weight: 600;
}
.popoverContent {
  width: 230px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  div {
    width: 50%;
  }
  label {
    margin-bottom: 10px;
  }
}
.batch {
  width: 80px;
  cursor: pointer;
  div {
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  div.active {
    background: #008bcf;
    color: #fff;
  }
  div:hover:not(.active) {
    background: #ccc;
    color: #fff;
  }
}

.spaceAroundBtn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .anticon {
    font-size: 16px;
    margin-right: 8px;
  }
}
.SettingOutlinedBox {
  position: relative;
  top: 11px;
  height: 14px;
  padding: 0;
  border-color: #fff;
  border: none;
  border-radius: 50%;
  color: #8c8c8c;
  margin-left: 30px;
  font-size: 14px;
  cursor: pointer;
}
.SettingOutlinedBox:hover {
  color: #008bcf;
}
</style>