import request from "@/utils/request.js";
//获取列表
export function getList(params, data, type) {
  return request()({
    method: "get",
    url: `/api/tdm/v1/affiliate/${type}/list`,
    data,
    params,
  });
}
//受邀请验证
export function acceptInvite(data, type) {
  return request().get(
    `/api/tdm/v1/affiliate/${type}/invite/accept/code/verify`,
    {
      params: data,
    }
  );
}

//受邀请验证 2带编辑
export function invite(data, code, type) {
  // console.log(params, data);
  return request()({
    method: "post",
    url: `/api/tdm/v1/affiliate/${type}/invite/accept/data/edit`,
    data,
    params: { code: code },
  });
}
//新建
export function create(data, type) {
  return request().post(`/api/tdm/v1/affiliate/${type}/create`, data);
}
//编辑关联企业
export function edit(data, type) {
  return request()({
    method: "post",
    url: `/api/tdm/v1/affiliate/${type}/status`,
    params: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//邀请
export function idInvite(data, type) {
  return request()({
    method: "post",
    url: ` /api/tdm/v1/affiliate/${type}/invite/sender/code/create`,
    params: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//获取详情
export function getDetails(id, type) {
  return request()({
    method: "get",
    url: `/api/tdm/v1/affiliate/${type}/detail`,
    params: { id: id },
  });
}
//更新用户
export function update(id, data, type) {
  return request()({
    method: "post",
    url: `/api/tdm/v1/affiliate/${type}/update`,
    params: { id: id },
    data,
  });
}

//获取联系人电话号码
export function phone(params, type) {
  return request()({
    method: "get",
    url: `/api/tdm/v1/affiliate/${type}/contact/phone`,
    params,
  });
}

//删除
export function delData(id, type) {
  return request()({
    method: "post",
    url: `/api/tdm/v1/affiliate/${type}/delete`,
    params: { id: id },
  });
}

//获取邀请协议
export function senderProtocol(id, type) {
  return request()({
    method: "get",
    url: `/api/tdm/v1/affiliate/${type}/invite/sender/protocol`,
    params: { id: id },
  });
}
//确认邀请协议
export function protocolConfirm(params, type) {
  return request()({
    method: "post",
    url: `/api/tdm/v1/affiliate/${type}/invite/accept/protocol/confirm`,
    params,
  });
}
//根据code查询数据列表
export function acceptData(code, type) {
  return request()({
    method: "get",
    url: `/api/tdm/v1/affiliate/${type}/invite/accept/data`,
    params: { code: code },
  });
}
//获取邀请信息（已邀请）
export function senderCodeInfo(id, type) {
  return request()({
    method: "post",
    url: `/api/tdm/v1/affiliate/${type}/invite/sender/code/info`,
    params: { id: id },
  });
}
//作废邀请码
export function invalid(id, type) {
  return request()({
    method: "post",
    url: `/api/tdm/v1/affiliate/${type}/invite/sender/code/invalid`,
    params: { id: id },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//获取受邀帮助信息
export function help(type) {
  return request()({
    method: "get",
    url: `/api/tdm/v1/affiliate/${type}/invite/accept/start/help`,
  });
}
//获取默认的标签
export function getOptions(type) {
  return request()({
    method: "get",
    url: `/api/tdm/v1/affiliate/${type}/tags/options`,
  });
}
